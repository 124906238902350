// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseApi: 'https://api.kalmarenergi.se/customer-formula/',
  baseApi: "https://ke-apim-dev.azure-api.net/customer-formula/",
  // baseApi: 'http://localhost:7001/',
  // postalApi: "https://api.kalmarenergi.se/postal/",
  postalApi: "https://ke-apim-dev.azure-api.net/postal/",
  // postalApi: 'http://localhost:9006/',
};
